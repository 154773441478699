import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import axiosInstance from "../Auth/axiosInstance"; // Import your Axios instance
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";


const RegisterPage = () => {

  const [passwordVisibility, setPasswordVisibility] = useState({
    registerPassword: false,
    confirmPasswordpassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    // phone:"",
    password: "",
    confirmPassword: "",

    rememberMe: false, // State for Remember Me checkbox
  });

  const [errors, setErrors] = useState({}); // State for storing error messages
  const navigate = useNavigate();

  AOS.init({ duration: 1200 });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Clear error when user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let formErrors = {};

    // Validate first name
    if (!formData.firstName) {
      formErrors.firstName = "First name is required";
    }

    // Validate last name
    if (!formData.lastName) {
      formErrors.lastName = "Last name is required";
    }

    // Validate email
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }

    // Validate password
    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      formErrors.password = "Password must be at least 8 characters long";
    }

    // Validate confirm password
    if (!formData.confirmPassword) {
      formErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.confirmPassword !== formData.password) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Prevent form submission if validation fails

    try {
      const response = await axiosInstance.post("/api/register", {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        // phone:formData.phone,
        password_confirmation: formData.confirmPassword,
      });
      const token = response.data.token;
      if (token) {
        localStorage.setItem('authToken', token);
      }
      navigate("/login"); // Navigate to login after registration
      toast.success("Registration successful! Please login.");
    } catch (error) {
      console.error("Registration error:", error.response?.data?.message || error.message);
      toast.error("Registration failed");// Toastify error message
    }
  };

  return (
    <>
            <Helmet>
  <title>Register Here - Shape Shifting Mastery </title> 
  <meta name="description" content={`Register at - Shape Shifting Mastery`} />
</Helmet>
   
    <div className="form_container">
      <div className="container-fluid">
        <div className="row d-flex row_form justify-content-center align-items-center flex-wrap-reverse" data-aos="zoom-in">
          <div className="col-lg-6 form_bg p-0 m-0">
            <div className="form_main">
              <div className="form">
                <div className="row heading text-center col-12">
                  <div className="logo-auth">
                    <img src="/logo.png" alt="Logo" />
                  </div>
                  <h2>Create an Account</h2>
                  <p>Join us and explore the possibilities</p>
                </div>

                <form id="form_register" onSubmit={handleRegister}>
                  {/* First Name */}
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="firstName">First Name</label>
                    </div>
                    <div className="col-12 form_input">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Enter Your First Name"
                          id="firstName"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.firstName && <div className="error-message">{errors.firstName}</div>}
                      </div>
                    </div>
                  </div>

                  {/* Last Name */}
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="lastName">Last Name</label>
                    </div>
                    <div className="col-12 form_input">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Enter Your Last Name"
                          id="lastName"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.lastName && <div className="error-message">{errors.lastName}</div>}
                      </div>
                    </div>
                  </div>

                  {/* Email */}
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="col-12 form_input">
                      <div className="input-group">
                        <input
                          type="email"
                          placeholder="Enter Your Email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                        {errors.email && <div className="error-message">{errors.email}</div>}
                      </div>
                    </div>
                  </div>

                  {/* Password */}
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="col-12 form_input">
                      <div className="input-group">
                        <input
                          type={passwordVisibility.registerPassword ? "text" : "password"}
                          placeholder="Enter Your Password"
                          id="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          required
                        />
                         <span
                            className="icon-show-hide"
                            onClick={() => togglePasswordVisibility("registerPassword")}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordVisibility.registerPassword ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                              <i className="fa-solid fa-eye"></i>
                            )}
                          </span>
                        {errors.password && <div className="error-message">{errors.password}</div>}
                      </div>
                    </div>
                  </div>

                  {/* Confirm Password */}
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                    </div>
                    <div className="col-12 form_input">
                      <div className="input-group">
                        <input
                          type={passwordVisibility.confirmPassword ? "text" : "password"}
                          placeholder="Confirm Your Password"
                          id="confirmPassword"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                          required
                        />
                         <span
                            className="icon-show-hide"
                            onClick={() => togglePasswordVisibility("confirmPassword")}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordVisibility.confirmPassword ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                              <i className="fa-solid fa-eye"></i>
                            )}
                          </span>
                        {errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
                      </div>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="row">
                    <div className="col-12">
                      <input
                        className="btn w-100 theme_btn"
                        value="Register"
                        type="submit"
                      />
                    </div>
                  </div>

                  {/* Login Link */}
                  <div className="row">
                    <div className="col-12 text-center">
                      <span className="subscription-info">
                        Already have an account?
                        <b style={{ paddingLeft: "5px" }}>
                          <Link
                            to={'/login'}
                            className="subscription-link"
                            style={{ cursor: "pointer" }}
                          >
                            Login Now
                          </Link>
                        </b>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-lg-6 d-none d-lg-block p-0 col-md-6 form_img_container">
            <div className="form-img">
              <Link to={``}>
                <img loading="lazy" src="assets/images/login-poster.png" alt="form_img" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default RegisterPage;
