import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';

const MyPhilosophy = () => {
    const [MyPhilosophyData, setMyPhilosophyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const BASE_URL = process.env.REACT_APP_APP_URL ;
    const API_URL = `${BASE_URL}/api/v1/topic/178`; // API URL for Philosophy

    useEffect(() => {
        AOS.init(
            {
                duration: 1200
              }
        ); // Initialize AOS animations
        const fetchMyPhilosophyData = async () => {
            try {
                const response = await axios.get(API_URL);
                // console.log("API Response:", response.data);  // Log the response to check
                const topic = response.data.topic[0]; // Access the first item in the topic array
                setMyPhilosophyData(topic);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchMyPhilosophyData();
    }, [API_URL]); // Add API_URL as a dependency
   
    const fieldValue = MyPhilosophyData?.fields?.find((field) => field.title === "Component-title")?.value || "Title Not Found";

    
    if (loading) {
        return (
          
         <section className="inner-banner-section skeleton-container">
                <div className="container">
                    <div className="row">
                        <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                            <div className="banner-caption">
                                <div>
                                    <h5 className="text-banner-badge hero skeleton"> </h5>
                                    <h1 className="main-heading hero mb-0 skeleton"> </h1>
                                </div>
                                <p className="pera hero skeleton">
                                    
                                </p>
                                <div className="d-flex gap-3 justify-content-between">
                                <div  className="btn-skeleton hero skeleton"></div>
                                <div className="hero-btn-container d-flex gap-1">
                                    <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                            <div className="banner-img">
                                <div className="video hero  skeleton"></div>
                            </div>
                        </div>
                    </div>
                </div>
         </section>
      
        );
      }
    if (error) return <p>{error}</p>;

    return (
        MyPhilosophyData && (
            <section className="inner-banner-section about-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-right">
                            <div className="banner-caption">
                                <div>
                                    <h5 className="text-banner-badge">
                                        {fieldValue || "No titlee"}
                                    </h5>
                                    <h1 className="main-heading mb-0">
                                        {MyPhilosophyData.details?.match(/<h1>(.*?)<\/h1>/)?.[1] ||
                                            "Transform Your Health: A 60-Day Metamorphosis with Michele Drake"}
                                    </h1>
                                </div>
                                <p className="pera" dangerouslySetInnerHTML={{ __html: MyPhilosophyData.details }} />
                                <div className="d-flex gap-3 justify-content-between">
                                    <Link to="https://masterhabits.com/" target="_blank" className="btn theme_btn">
                                        Take a Quiz Now
                                    </Link>
                                    <Link to="/podcasts" className="podcast-btn">
                                        <div className="waves wave-1"></div>
                                        <div className="waves wave-2"></div>
                                        <div className="waves wave-3"></div>
                                        <span>
                                          <i className="fa fa-play"></i>
                                        </span>
                                        <p className="podcast-text-none">Free Podcast</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-left">
                            <div className="banner-img">
                                <video controls={true}
                                    autoplay="" loop="" muted="muted" playsInline="" 
                                    src={
                                        MyPhilosophyData.video_file ||
                                        "https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    );
};

export default MyPhilosophy;
