import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const SocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([]);
  const BASE_URL = process.env.REACT_APP_APP_URL;

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/topic/217`);
        const fields = response.data.topic[0]?.fields;
        
        if (fields) {
          setSocialLinks(fields);
        }
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchSocialLinks();
  }, [BASE_URL]);

  return (
    <>
      {socialLinks.map((link, index) => {
        let iconClass = `fa-brands fa-${link.title.toLowerCase()}`;
        
        // Special case for Twitter
        if (link.title === "X") {
          iconClass = "fa-brands fa-x-twitter";
        }
        
        return (
          <li key={index}>
            <Link to={link.value} target="_blank" rel="noopener noreferrer">
              <i className={iconClass}></i>
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default SocialLinks;
