import './setJQuery';
import React from "react";
import AllRoutes from './Route';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ---all-stylesheets---- 
import './assets/css/stellarnav.css';
import './assets/css/style.css';
import './assets/css/skeleton.css';
import './assets/css/responsive.css';
import { HelmetProvider } from "react-helmet-async";
import { ProfileProvider } from "./Auth/profileContext"; // Import ProfileProvider
const App = () => {
    return (
        <>
        <ProfileProvider>
        <HelmetProvider>
            <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored" />

            <AllRoutes />
            </HelmetProvider>
            </ProfileProvider>
        </>
    );
};

export default App;
