import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import Header from "../components/Header"; // Directly import Header without lazy loading
import Testimonials from "../components/Testimonials"; // Other components may still be lazy-loaded
import Footer from "../components/Footer";
import WhyChooseUs from "../components/WhyChooseUs";
import ErrorSection from "../components/ErrorSection";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  const [data, setData] = useState({
    aboutData: null,
    whyChooseUsData: null,
    aboutsectionData: null,
    videoData:null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URLS = {
    about: `${BASE_URL}/api/v1/topic/178`,
    whyChooseUs: `${BASE_URL}/api/v1/topic/184`,
    aboutSection: `${BASE_URL}/api/v1/topic/190}`,
    videoData: `${BASE_URL}/api/v1/topic/182`,
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
    }); // Initialize AOS animations
    
    const fetchData = async () => {
      try {
        const [aboutResponse, whyChooseUsResponse, aboutsectionResponse,videoDataResponse] = await Promise.all([
          axios.get(API_URLS.about),
          axios.get(API_URLS.whyChooseUs),
          axios.get(API_URLS.aboutSection),
          axios.get(API_URLS.videoData),

        ]);

        setData({
          aboutData: aboutResponse.data.topic[0],
          whyChooseUsData: whyChooseUsResponse.data.topic[0],
          aboutsectionData: aboutsectionResponse.data.topic[0],
          videoData: videoDataResponse.data.topic[0]?.video_file,
        });
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [API_URLS.about, API_URLS.whyChooseUs, API_URLS.aboutSection, API_URLS.videoData]);


  if (loading) {
    return (
      <>
      <Header />
      <section className="inner-banner-section skeleton-container">
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <div>
                  <h5 className="text-banner-badge hero skeleton"> </h5>
                  <h1 className="main-heading hero mb-0 skeleton"> </h1>
                </div>
                <p className="pera hero skeleton"> </p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      </>
    );
  }

  if (error) return <ErrorSection message = {error} />

  const { aboutData, whyChooseUsData, aboutsectionData , videoData } = data;

  const headingField = whyChooseUsData?.fields?.find((field) => field.type === 0);
  const heading = headingField?.value || "Default Heading";

  const stepsFields = whyChooseUsData?.fields?.filter((field) => field.type !== 0);

  const paragraphOne = aboutsectionData?.fields?.find((field) => field.title === "Paragraph_One")?.value || "Default Paragraph One Text...";
  const paragraphTwo = aboutsectionData?.fields?.find((field) => field.title === "Paragraph_two")?.value || "Default Paragraph Two Text...";
  const imageMichele = aboutsectionData?.fields?.find((field) => field.type === 8)?.value || null;
  const fieldValue = aboutData?.fields?.find((field) => field.title === "Component-title")?.value || "Title Not Found";

  return (
    <>
      <Header />
      <Helmet>

        <title>About Us - Shape Shifting Mastery </title>
        <meta name="description" content="Helmet application" />
    </Helmet>

      {/* Banner Section */}
      <section className="inner-banner-section about-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-right">
              <div className="banner-caption">
                <h5 className="text-banner-badge">{fieldValue || "My Philosophy"}</h5>
                <h1 className="main-heading mb-0">{aboutData?.details?.match(/<h1>(.*?)<\/h1>/)?.[1] || "Transform Your Health: A 60-Day Metamorphosis with Michele Drake"}</h1>
                <p className="pera">{aboutData?.details?.match(/<p>(.*?)<\/p>/)?.[1] || "Default description text..."}</p>
                <div className="d-flex gap-3 justify-content-between">
                  <Link to="https://masterhabits.com/" target="_blank" className="btn theme_btn">Take a Quiz Now</Link>
                  <Link to="/podcasts" className="podcast-btn">
                    <div className="waves wave-1"></div>
                    <div className="waves wave-2"></div>
                    <div className="waves wave-3"></div>
                    <span><i className="fa fa-play"></i></span> <p className="podcast-text-none">Free Podcast</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-left">
            <div className="banner-img">
            <video controls={true} autoPlay loop="" muted="muted" playsInline=""  src={videoData || "no video found"} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="sec_padding about-section ">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="about-img" data-aos="zoom-in-right">
                <img src={imageMichele ? `${BASE_URL}/uploads/topics/${imageMichele}` : "assets/images/Michele.png"} alt="Michele" loading="lazy" />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6" data-aos="zoom-in-left">
              <h2 className="sec_heading m-0">Meet <span className="sec_heading-stylish">Michele</span></h2>
              <p className="pera">{paragraphOne}</p>
              <Link to="https://masterhabits.com/" target="_blank" className="btn theme_btn">Take a Quiz Now</Link>
            </div>
          </div>
          <div className="col-md-12  mt-2 mt-md-0" data-aos="zoom-in-up">
            <p className="pera">{paragraphTwo}</p>
          </div>
        </div>
      </section>

      {/* Step Process Section */}
      <section className="sec_padding step-process">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4 mb-3" data-aos="fade-right">
              <h2 className="sec_heading-stylish text-center">4 Step Process</h2>
              <h6 className="sub_heading text-center">{heading}</h6>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12" data-aos="fade-up">
              <ul className="steps-container py-md-3">
                {stepsFields?.map((field, index) => (
                  <li key={index} className="step">
                    <span className="step-number">{index + 1}</span>
                    <h3 className="title">{field.title}</h3>
                    <p className="pera">{field.value}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 border-top pt-3 border-theme" data-aos="fade-up">
              <p className="pera">{whyChooseUsData?.details}</p>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <WhyChooseUs/>
      <Footer />
    </>
  );
};

export default AboutPage;
