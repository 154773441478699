import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify"; // Import react-toastify
import axiosInstance from "../Auth/axiosInstance";

const PasswordSecurity = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    newPassword_confirmation: false,
  });

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    newPassword_confirmation: "",
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const oldToken = localStorage.getItem('authToken');
    // Check if old password and new password are the same
    if (formData.oldPassword === formData.newPassword) {
      toast.error("The new password cannot be the same as the old password.");
      return;
    }

    
    if (formData.newPassword !== formData.newPassword_confirmation) {
      toast.error("The new password and confirm password do not match.");
      return;
    }

    
    try {
      
      const response = await axiosInstance.post('/api/updatePassword', formData,
        {
          headers: {
            'Authorization': `Bearer ${oldToken}`, // Use old token to authenticate
          },
        });
      localStorage.removeItem('authToken');
      localStorage.setItem('authToken', response.data.token);
      axios.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
      toast.success(response.data.message);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage); 
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="card mb-2">
      <h5 className="card-header profile-sec_heading">Security</h5>
      <div className="card-body">
        {/* Change Password */}
        <form id="formChangePassword" onSubmit={handleSubmit}>
          <div className="row">
            {/* Old Password */}
            <div className="mb-3 col-12 col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="oldPassword">
                Old Password
              </label>
              <div className="input-group">
                <input
                  className="form-control theme_input"
                  type={passwordVisibility.oldPassword ? "text" : "password"}
                  id="oldPassword"
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={handleInputChange}
                  placeholder="********"
                />
                <span
                  className="icon-show-hide"
                  onClick={() => togglePasswordVisibility("oldPassword")}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisibility.oldPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>

            {/* New Password */}
            <div className="mb-3 col-12 col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="newPassword">
                New Password
              </label>
              <div className="input-group">
                <input
                  className="form-control theme_input"
                  type={passwordVisibility.newPassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  placeholder="********"
                />
                <span
                  className="icon-show-hide"
                  onClick={() => togglePasswordVisibility("newPassword")}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisibility.newPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>

            {/* Confirm Password */}
            <div className="mb-3 col-12 col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <div className="input-group">
                <input
                  className="form-control theme_input"
                  type={passwordVisibility.newPassword_confirmation ? "text" : "password"}
                  name="newPassword_confirmation"  
                  id="newPassword_confirmation"
                  value={formData.newPassword_confirmation}
                  onChange={handleInputChange}
                  placeholder="********"
                />
                <span
                  className="icon-show-hide"
                  onClick={() => togglePasswordVisibility("newPassword_confirmation")}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisibility.newPassword_confirmation ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-12 w-100">
              <button type="submit" className="btn theme_btn">
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordSecurity;
