import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Podcast from "../components/Podcast";
import { Helmet } from "react-helmet";

const PodcastPage = () => {
    return (
        <>
        
            <Header />
            <Helmet>
  <title>Podcasts - Shape Shifting Mastery </title> {/* Replace "Your Blog Name" with your site's name */}
  <meta name="description" content={`Read more about our Podcasts`} />
</Helmet>
            <Podcast />
            <Footer />
        </>
    );
};

export default PodcastPage;
