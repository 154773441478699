import React, { createContext, useState, useContext } from 'react';

// Create Context
const ProfileContext = createContext();

// Provider Component
export const ProfileProvider = ({ children }) => {
  const [profileImage, setProfileImage] = useState('/assets/images/Michele.png'); // Default image

  const updateProfileImage = (newImage) => {
    setProfileImage(newImage);
  };

  return (
    <ProfileContext.Provider value={{ profileImage, updateProfileImage }}>
      {children}
    </ProfileContext.Provider>
  );
};

// Custom Hook to use the Profile Context
export const useProfile = () => {
  return useContext(ProfileContext);
};
