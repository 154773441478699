import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../Auth/axiosInstance";
import { toast } from "react-toastify";
import { useProfile } from "../Auth/profileContext"; // Import the custom hook

const UserSidebar = () => {
  const { profileImage, updateProfileImage } = useProfile(); // Access profileImage and updateProfileImage from context
  const [userDetails, setUserDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const [previewImage, setPreviewImage] = useState(null); // State for the image preview
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showEmailMessage, setShowEmailMessage] = useState(false); // State to control visibility of the email message

  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_APP_URL;

  // Fetch user details when component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          navigate("/login");
          return;
        }

        const response = await axiosInstance.get("api/userdetails", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setUserDetails(response.data.data);
          
          updateProfileImage(`${BASE_URL}${response.data.data.photo || "/assets/images/Michele.png"}`);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/login");
      }
    };

    fetchUserDetails();
  }, [navigate, BASE_URL, updateProfileImage]);

  // Handle image upload and show preview
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result); // Set preview image immediately
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle saving profile image
  const handleSaveImage = async () => {
    setIsLoading(true); // Set loading state
    try {
      const token = localStorage.getItem("authToken");
      const formData = new FormData();
      const file = document.getElementById("imageUpload").files[0];
      if (file) {
        formData.append("photo", file);

        const response = await axiosInstance.post("api/updateavatar", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          setUserDetails((prev) => ({
            ...prev,
            photo: response.data.photo,
          }));
          updateProfileImage(`${BASE_URL}${response.data.photo}`); // Update global profile image
          toast.success("Image updated successfully");
        }
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
      toast.error("Failed to upload image");
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        toast.error("No token found, redirecting to login.");
        navigate("/login");
        return;
      }

      const response = await axiosInstance.get("api/logout", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        localStorage.removeItem("authToken");
        toast.success("Logout Successful!");
        navigate("/login");
      } else {
        toast.error("Unexpected response during logout.");
      }
    } catch (error) {
      console.error("Error logging out:", error);
      if (error.response?.status === 401) {
        toast.error("Session expired. Please log in again.");
      } else {
        toast.error("Failed to log out. Please try again.");
      }
      localStorage.removeItem("authToken");
      navigate("/login");
    }
  };

  // Handle saving changes to user details
  const handleSaveChanges = async (e) => {
    e.preventDefault();

    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;

    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.post(
        "api/update-user",
        {
          firstName,
          lastName,
          email,
          phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUserDetails(response.data.user); // Update user details state
       
        setIsModalOpen(false); // Close modal after saving
        toast.success("User details updated successfully");
        
      }
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to save changes");
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const handleEmailFocus = () => {
    setShowEmailMessage(true); // Show message when email input is focused
  };

  const handleEmailBlur = () => {
    setShowEmailMessage(false); // Hide message when focus is lost
  };


  // Show skeleton loader while userDetails is loading
  if (!userDetails) {
    return (
      <div className="card mb-2 skeleton-container skeleton-container-user">
        <div className="card-body">
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              <div className="avatar-upload skeleton"></div>
              <div className="user-name skeleton mb-2"></div>
            </div>
          </div>
          <div className="border-bottom py-2"></div>
          <div className="d-flex justify-content-between align-items-center py-2">
            <div className="profile-sec_heading skeleton"></div>
            <span className="edit skeleton"></span>
          </div>
          <div className="info-container">
            <ul className="list-unstyled">
              <li className="mb-1 data-user skeleton"></li>
              <li className="mb-1 data-user skeleton pt-1"></li>
              <li className="mb-1 data-user skeleton pt-1"></li>
              <li className="mb-1 data-user skeleton pt-1"></li>
              <li className="mb-1 data-user skeleton pt-1"></li>
              <li className="mt-3 d-flex gap-1">
                <div className="btn skeleton"></div>
                <div className="btn skeleton"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  // Destructure first and last name from user details
  const [firstName, lastName] = userDetails.name
    ? userDetails.name.split(" ")
    : ["Unknown", "User"];

  return (
    <>
      <div className="card mb-2">
        <div className="card-body">
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    onChange={handleImageUpload} // Handle image upload immediately
                  />
                  <label htmlFor="imageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <img
                    loading="lazy"
                    src={previewImage || profileImage} // Use preview image if available, otherwise use global image
                    height="100"
                    width="100"
                    alt="User avatar"
                  />
                </div>
              </div>
              <div className="user-info text-center">
                <h4 className="user-name">{`${firstName} ${lastName}`}</h4>
              </div>
            </div>
          </div>
          <div className="border-bottom py-2"></div>
          <div className="d-flex justify-content-between py-2">
            <h3 className="profile-sec_heading">Details</h3>
            <Link to="#" onClick={() => setIsModalOpen(true)}>
              <i className="fa fa-edit"></i>
            </Link>
          </div>
          <div className="info-container">
            <ul className="list-unstyled">
              <li className="mb-1">
                <span className="fw-medium me-1">First Name:</span>
                <span>{firstName}</span>
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Last Name:</span>
                <span>{lastName}</span>
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Email:</span>
                {userDetails.email}
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Number:</span>
                {userDetails.phone || "Not provided"}
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Status:</span>
                <span className={`badge bg-${userDetails.status ? "success" : "danger"}`}>
                  {userDetails.status ? "Active" : "Inactive"}
                </span>
              </li>
              <li className="mt-3 d-flex gap-1">
                <button onClick={handleSaveImage} className="btn theme_btn" disabled={isLoading}>
                  Save Image
                </button>
                <button onClick={handleLogout} className="btn btn-logout">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          aria-labelledby="editModalLabel"
          aria-hidden={!isModalOpen}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editModalLabel">
                  Edit User Details
                </h5>
                <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} />
              </div>
              <div className="modal-body">
                <form onSubmit={handleSaveChanges}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">First Name</label>
                    <input type="text" id="firstName" className="form-control" defaultValue={firstName} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Last Name</label>
                    <input type="text" id="lastName" className="form-control" defaultValue={lastName} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
            type="email"
            id="email"
            className="form-control"
            defaultValue={userDetails.email}
            readOnly
            onFocus={handleEmailFocus} // Trigger focus event to show message
            onBlur={handleEmailBlur}   // Trigger blur event to hide message
          />
          {showEmailMessage && (
            <small className="form-text text-muted">Email cannot be changed</small>
          )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input type="tel" id="phone" className="form-control" defaultValue={userDetails.phone} />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    {isLoading ? "Saving..." : "Save Changes"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSidebar;
