import React, { useState, useEffect } from "react";
import axiosInstance from "../Auth/axiosInstance"; // Make sure to import axiosInstance correctly
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify styles

const AddressForm = () => {
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/addresses`;

  const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (authToken) {
          // Fetch saved addresses
          const response = await axiosInstance.get(API_URL, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (response.data.billing_address) {
            setBillingAddress(response.data.billing_address);
          }
          if (response.data.shipping_address) {
            setShippingAddress(response.data.shipping_address);
          }
        }
      } catch (error) {
        toast.error("Failed to load addresses. Please try again.");
      }
    };

    fetchAddresses();
  }, []); // Fetch addresses on component mount

  const handleBillingChange = (e) => {
    const { id, value } = e.target;
    const field = id.replace("billing_", ""); // Extract field name
    setBillingAddress((prev) => ({ ...prev, [field]: value }));
    if (isShippingSameAsBilling) {
      setShippingAddress((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleShippingChange = (e) => {
    const { id, value } = e.target;
    const field = id.replace("shipping_", ""); // Extract field name
    setShippingAddress((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckboxChange = () => {
    setIsShippingSameAsBilling((prev) => !prev);
    if (!isShippingSameAsBilling) {
      setShippingAddress({ ...billingAddress });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      toast.error("You need to be logged in to save addresses.");
      return;
    }

    const data = {
      billing_address: billingAddress,
      shipping_address: isShippingSameAsBilling ? billingAddress : shippingAddress,
    };

    try {
      const response = await axiosInstance.post(API_URL, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success("Address saved successfully!"); // Show success toast
    } catch (error) {
      toast.error("Failed to save address. Please try again."); // Show error toast
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {/* Billing Address Card */}
        <div className="card">
          <h5 className="card-header">Billing Address</h5>
          <div className="card-body">
            <form id="billingAddressForm">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="billing_name" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="billing_name"
                    placeholder="Enter your full name"
                    value={billingAddress.name}
                    onChange={handleBillingChange}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="billing_street" className="form-label">
                    Street Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="billing_street"
                    placeholder="Enter your street address"
                    value={billingAddress.street}
                    onChange={handleBillingChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="billing_city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="billing_city"
                    placeholder="Enter your city"
                    value={billingAddress.city}
                    onChange={handleBillingChange}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="billing_state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="billing_state"
                    placeholder="Enter your state"
                    value={billingAddress.state}
                    onChange={handleBillingChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="billing_zip" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="billing_zip"
                    placeholder="Enter your zip code"
                    value={billingAddress.zip}
                    onChange={handleBillingChange}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="billing_country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="billing_country"
                    placeholder="Enter your country"
                    value={billingAddress.country}
                    onChange={handleBillingChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div>
          <div className="form-check my-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="sameAsBilling"
              checked={isShippingSameAsBilling}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="sameAsBilling">
              Shipping address is the same as billing address
            </label>
          </div>
        </div>

        {/* Shipping Address Card */}
        <div className="card">
          <h5 className="card-header">Shipping Address</h5>
          <div className="card-body">
            <form id="shippingAddressForm">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shipping_name" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shipping_name"
                    placeholder="Enter your full name"
                    value={shippingAddress.name}
                    onChange={handleShippingChange}
                    disabled={isShippingSameAsBilling}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shipping_street" className="form-label">
                    Street Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shipping_street"
                    placeholder="Enter your street address"
                    value={shippingAddress.street}
                    onChange={handleShippingChange}
                    disabled={isShippingSameAsBilling}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shipping_city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shipping_city"
                    placeholder="Enter your city"
                    value={shippingAddress.city}
                    onChange={handleShippingChange}
                    disabled={isShippingSameAsBilling}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shipping_state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shipping_state"
                    placeholder="Enter your state"
                    value={shippingAddress.state}
                    onChange={handleShippingChange}
                    disabled={isShippingSameAsBilling}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shipping_zip" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shipping_zip"
                    placeholder="Enter your zip code"
                    value={shippingAddress.zip}
                    onChange={handleShippingChange}
                    disabled={isShippingSameAsBilling}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shipping_country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shipping_country"
                    placeholder="Enter your country"
                    value={shippingAddress.country}
                    onChange={handleShippingChange}
                    disabled={isShippingSameAsBilling}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-end">
            <button
              type="submit"
              className="btn theme_btn mt-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
    </div>
      </div>
    
  );
};

export default AddressForm;
