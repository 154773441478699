import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import BlogPage from "./pages/BlogPage";
import ShopPage from "./pages/ShopPage";
import ContactPage from "./pages/ContactPage";

import BlogDetailPage from "./pages/BlogDetailPage";
import ShopDetailPage from "./pages/ShopDetailPage";
import PodcastDetailPage from "./pages/PodcastDetailPage";
import PodcastPage from "./pages/PodcastPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import UpcomingSessionInfo from "./pages/UpcomingSessionInfo";

import Comment from './components/Comment';
import ServiceDetailPage from './pages/ServiceDetailPage';
import Error404 from './pages/404';
import Welcome from './pages/Welcome';
import UserProfile from './pages/UserProfile';
import ScrollToTop from './components/ScrollToTop';

const AllRoutes = () => {
    return (
        <Router>
          
                <ScrollToTop /> {/* Ensures pages scroll to the top */}
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shape-shifting-mastery" element={<Welcome />} />

                <Route path="/about" element={<AboutPage />} />
                <Route path="/services" element={<ServicePage />} />
                <Route path="/services/:seo_url_slug" element={<ServiceDetailPage />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/blogs/:category/:seo_url_slug" element={<BlogDetailPage />} />
                {/* <Route path="/blogs/:categorySlug?" component={<BlogPage/>} /> */}

                <Route path="/shop" element={<ShopPage />} />
                <Route path="/shop/:categorySlug/:productSlug" element={<ShopDetailPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/podcasts" element={<PodcastPage />} />
                <Route path="/podcasts/:seo_url_slug" element={<PodcastDetailPage />} />
            

                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                {/* <Route path="/comment" element={<Comment />} /> */}
                <Route path="/404" element={<Error404 />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/UserProfile" element={<UserProfile />} />
                <Route path="/upcoming-session-info" element={<UpcomingSessionInfo />} />
                
                {/* Catch-All Route for 404 */}
                <Route path="*" element={<Error404 />} />
                </Routes>
            
        </Router>
    );
};

export default AllRoutes;
