import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../Auth/axiosInstance";
import { toast } from "react-toastify";

const CurrentPlan = () => {
  const [plan, setPlan] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [newPlan, setNewPlan] = useState("basic");
  const [reason, setReason] = useState("");
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the current user plan
    const fetchPlan = async () => {
      try {
        const response = await axiosInstance.get("/api/user/plan");
    
        if (response.data && response.data.plan) {
          // Check if the plan exists in the response
          setPlan(response.data.plan); // Set the current user plan
        } else {
          setPlan(null); // No plan found
        }
      } catch (error) {
        console.error("Error fetching user plan:", error.response?.data || error.message);
        toast.error("Error fetching user plan!"); // Toastify error message
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, []);

  const handleCancelSubscription = () => {
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/api/user/plan/cancel", { reason, feedback });
      setPlan(null);  // Update state to show no active plan
      setShowCancelModal(false);
      toast.success("Subscription cancelled successfully!"); // Success toast

      // Close the cancel subscription modal manually after successful cancellation
      if (window.bootstrap) {
        const cancelModal = new window.bootstrap.Modal(document.getElementById("cancelSubscriptionModal"));
        cancelModal.hide(); // Close the modal
      }
    } catch (error) {
      console.error("Error canceling subscription:", error.response?.data || error.message);
      toast.error("Error canceling subscription!"); // Error toast
    }
  };

  const handleUpgradePlan = async () => {
    try {
      const response = await axiosInstance.post("/api/user/plan", { plan: newPlan });
      toast.success("Plan upgraded successfully!"); // Success toast
      setPlan((prevPlan) => ({ ...prevPlan, plan: newPlan }));

      // Close the upgrade modal manually after successful upgrade
      if (window.bootstrap) {
        const upgradeModal = new window.bootstrap.Modal(document.getElementById("upgradePlanModal"));
        upgradeModal.hide(); // Close the modal
      }
    } catch (error) {
      console.error("Error upgrading plan:", error.response?.data || error.message);
      toast.error("Error upgrading plan!"); // Error toast
    }
  };

  if (loading) {
    return (
      <>
        <div className="row skeleton-container skeleton-plan">
          <div className="col-12">
            <div className="card mb-2">
              <h5 className="card-header bg-white">
                <div className="heading skeleton"></div>
              </h5>
              <div className="card-body">
                <div className="row">
                  {/* Left Column */}
                  <div className="col-xl-6 order-1 order-xl-0">
                    <div className="mb-1">
                      <div className="mb-1 sub-heading skeleton"></div>
                      <div className="text skeleton"></div>
                    </div>
                    <div className="mb-1 pt-1">
                      <div className="sub-heading skeleton mb-1"></div>
                      <div className="text skeleton"></div>
                    </div>
                    <div className="text skeleton"></div>
                  </div>
                  {/* Buttons */}
                  <div className="col-12 order-2 mt-2 order-xl-0 d-flex gap-2">
                    <div className="btn skeleton"></div>
                    <div className="btn skeleton"></div>
                    <div className="btn skeleton"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <h5 className="card-header">Current Plan</h5>
            <div className="card-body">
              <div className="row">
                {/* Left Column */}
                <div className="col-xl-6 order-1 order-xl-0">
                  {plan ? (
                    <>
                      <div className="mb-1">
                        <h6 className="mb-1">Your Current Plan is {plan.plan}</h6>
                        <p>
                          {plan.plan === "basic"
                            ? "A simple start for everyone"
                            : plan.plan === "standard"
                            ? "For small to medium businesses"
                            : "For large enterprises"}
                        </p>
                      </div>
                      <div className="mb-1 pt-1">
                        <h6 className="mb-1">Active until {plan.active_until}</h6>
                        <p>We will send you a notification upon subscription expiration.</p>
                      </div>
                    </>
                  ) : (
                    <p>No active plan found. Please choose a plan to get started.</p> // Message when no plan is selected
                  )}
                </div>

                {/* Right Column */}
                <div className="col-xl-6 order-0 order-xl-0">
                  {/* Optionally add more details or alerts */}
                </div>

                {/* Buttons */}
                <div className="col-12 order-2 order-xl-0 d-flex gap-2">
                  {plan ? (
                    <button
                      className="btn theme_btn me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#upgradePlanModal"
                    >
                      Upgrade Plan
                    </button>
                  ) : (
                    <button
                      className="btn theme_btn me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#upgradePlanModal"
                    >
                      Choose Plan
                    </button>
                  )}
                  {plan && (
                    <button
                      className="btn tp_btn cancel-subscription"
                      onClick={handleCancelSubscription}
                      data-bs-toggle="modal"
                      data-bs-target="#cancelSubscriptionModal"
                    >
                      Cancel Subscription
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Upgrade Plan */}
          <div
            className="modal fade"
            id="upgradePlanModal"
            tabIndex="-1"
            aria-labelledby="upgradePlanModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="upgradePlanModalLabel">
                    Upgrade Your Plan
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="planType" className="form-label">
                        Select Plan
                      </label>
                      <select
                        className="form-select"
                        id="planType"
                        value={newPlan}
                        onChange={(e) => setNewPlan(e.target.value)}
                      >
                        <option value="basic">Basic - $199/month</option>
                        <option value="standard">Standard - $299/month</option>
                        <option value="premium">Premium - $399/month</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpgradePlan}
                  >
                    Upgrade Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Cancel Subscription */}
          <div
            className="modal fade"
            id="cancelSubscriptionModal"
            tabIndex="-1"
            aria-labelledby="cancelSubscriptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="cancelSubscriptionModalLabel">
                    Confirm Subscription Cancellation
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleFormSubmit}>
                    <div className="alert alert-danger" role="alert">
                      Are you sure you want to cancel your subscription?
                    </div>
                    <div className="mb-3">
                      <label htmlFor="reason" className="form-label">
                        Reason for Cancellation
                      </label>
                      <textarea
                        className="form-control"
                        id="reason"
                        rows="3"
                        placeholder="Please let us know why you're canceling"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="feedback" className="form-label">
                        Additional Feedback (Optional)
                      </label>
                      <textarea
                        className="form-control"
                        id="feedback"
                        rows="3"
                        placeholder="Any suggestions for improvement?"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-danger">
                        Cancel Subscription
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentPlan;
