import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import slugify from 'slugify';


const Podcast = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const podcastsPerPage = 4; // Display 4 podcasts per page
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topics/25/page/1/count/20`;

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        
        setPodcasts(data.topics || []); // Extract topics array
        setLoading(false);
      } catch (error) {
        console.error("Error fetching podcasts:", error);
        setLoading(false);
      }
    };

    fetchPodcasts();
  }, []);

  const indexOfLastPodcast = currentPage * podcastsPerPage;
  const indexOfFirstPodcast = indexOfLastPodcast - podcastsPerPage;
  const currentPodcasts = podcasts.slice(indexOfFirstPodcast, indexOfLastPodcast);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(podcasts.length / podcastsPerPage);
  
  if (loading) {
    return (
      <section className="inner-banner-section skeleton-container">
        {/* Loading skeleton */}
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <div>
                  <h5 className="text-banner-badge hero skeleton"> </h5>
                  <h1 className="main-heading hero mb-0 skeleton"> </h1>
                </div>
                <p className="pera hero skeleton"></p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      {currentPodcasts.map((podcast, index) => {
        // Generate SEO-friendly slug based on the podcast title and id
        const slug = slugify(podcast.seo_url_slug,{ lower: true, strict: true });
        const podcastSlug = `${slug}`;

        return (
          <div className="podcast-item" key={index}>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="banner-caption">
                    <h5 className="text-banner-badge">Services</h5>
                    <h1 className="main-heading mb-0">{podcast.title}</h1>
                    <p className="pera">{podcast.details}</p>
                    <div className="d-flex gap-3 justify-content-between">
                      <Link to={`/podcasts/${podcastSlug}`} className="btn theme_btn">
                        More Details
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="banner-img">
                    <video controls src={podcast.video_file} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* Pagination */}
      <div className="pagination">
        <Link
          to="#"
          onClick={() => handlePageChange(currentPage - 1)}
          className={`fa-solid icon pre ${currentPage === 1 ? "disabled" : ""}`}
        >
          <i className="fa-arrow-left"></i>
        </Link>
        <ul className="pagination-items">
          {Array.from({ length: totalPages }, (_, i) => (
            <Link to="#" key={i} onClick={() => handlePageChange(i + 1)}>
              <li className={`item ${currentPage === i + 1 ? "active" : ""}`}>{i + 1}</li>
            </Link>
          ))}
        </ul>
        <Link
          to="#"
          onClick={() => handlePageChange(currentPage + 1)}
          className={`fa-solid icon next ${currentPage === totalPages ? "disabled" : ""}`}
        >
          <i className="fa-arrow-right"></i>
        </Link>
      </div>
    </>
  );
};

export default Podcast;
