import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';

const Philosophy = () => {
    const [philosophyData, setPhilosophyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const BASE_URL = process.env.REACT_APP_APP_URL  ;
    const API_URL = `${BASE_URL}/api/v1/topic/168`; // API URL for Philosophy

    useEffect(() => {
        AOS.init(
            {
                duration: 1200
              }
        ); // Initialize AOS animations
        const fetchPhilosophyData = async () => {
            try {
                const response = await axios.get(API_URL);
                setPhilosophyData(response.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPhilosophyData();
    }, [API_URL]); // Add API_URL as a dependency

    const fieldValue =
    philosophyData?.topic[0]?.fields?.find((field) => field.title === "Component-title")?.value ||
    "Title Not Found";
  
    if (error) {
        return (
            <section className="sec_padding our-philosophy">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="sec_padding our-philosophy">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-md-4 mb-3 " data-aos="fade-right">
                        <h6 className="sub_heading text-center">{fieldValue}</h6>
                        <h2 className="sec_heading-stylish text-center">Together We Will    </h2>
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-right">
                        <div className="pera" dangerouslySetInnerHTML={{ __html: philosophyData?.topic[0]?.details || '' }} />
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-left">
                        <div className="right-img">
                            <img src={philosophyData?.topic[0]?.photo_file } alt="What We Do" />
                        </div>
                    </div>
                    <div className="col-12 text-center mt-3" data-aos="zoom-in-up">
                        <Link to="https://masterhabits.com/" target="_blank" className="btn theme_btn mt-3">Take a quiz now</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Philosophy;
