import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import axiosInstance from "../Auth/axiosInstance"; // Import your Axios instance
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const LoginPage = () => {

  // --add-toster-- 

  // const notify = () => {
  //   toast.success("Success message"); // For success notifications
  //   toast.error("Error message"); // For error notifications
  //   toast.info("Info message"); // For info notifications
  //   toast.warning("Warning message"); // For warning notifications
  // };


  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const [isRegistering, setIsRegistering] = useState(false); // State to toggle between login and registration
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false, // State for Remember Me checkbox
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(""); // State for forgot password email
  const [isForgotPassword, setIsForgotPassword] = useState(false); // State to toggle forgot password form
  const [errors, setErrors] = useState({}); // To store error messages
  const navigate = useNavigate();

  AOS.init({ duration: 1200 });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Clear the error when the user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let formErrors = {};

    // Validate email
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }

    // Validate password
    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Prevent form submission if validation fails

    try {
      const response = await axiosInstance.post("/api/login", {
        email: formData.email,
        password: formData.password,
        remember_me: formData.rememberMe, // Send rememberMe value to the backend
      });

      // Save token to local storage
      localStorage.setItem("authToken", response.data.token);

      // Redirect to dashboard after successful login
      toast.success("Login successful!"); // Toastify error message
      navigate("/");
    } catch (error) {
      console.error("Login error:", error.response?.data?.message || error.message);
      toast.error("Invalid email or password!"); // Toastify error message
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!forgotPasswordEmail) {
      alert("Please enter your email");
      return;
    }

    try {
      const response = await axiosInstance.post("api/forgot-password", {
        email: forgotPasswordEmail,
      });

      toast.success("Reset link sent to your email."); // Toastify error message
      setIsForgotPassword(false); // Close the forgot password form after success
    } catch (error) {
      console.error("Forgot Password error:", error.response?.data?.message || error.message);
      toast.error("Error sending reset link. Please check the email address"); // Toastify error message
    }
  };

  return (
    <>
       <Helmet>
  <title>Login - Shape Shifting Mastery </title> {/* Replace "Your Blog Name" with your site's name */}
  <meta name="description" content={` Login Here`} />
</Helmet>
    
    <div className="form_container">
      <div>
    </div>
      <div className="container-fluid">
        <div className="row d-flex row_form justify-content-center align-items-center flex-wrap-reverse" data-aos="zoom-in">
          <div className="col-lg-6 form_bg p-0 m-0">
            
            <div className="form_main">
              <div className="form">
                <div className="row heading text-center col-12">
                  <div className="logo-auth">
                    <img src="/logo.png" alt="Logo" />
                  </div>
                  <h2>Welcome Back</h2>
                  <p className="pera">The faster you fill, the faster you get</p>
                </div>

                <form id="form_logIn" onSubmit={handleLogin}>
                  {/* Show only the email and password fields when not in forgot password mode */}
                  {!isForgotPassword && (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="email">Email</label>
                        </div>
                        <div className="col-12 form_input">
                          <div className="input-group">
                            <input
                              type="email"
                              placeholder="Enter Your Email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.email && <div className="error-message">{errors.email}</div>}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="password">Password</label>
                        </div>
                        <div className="col-12 form_input">
                        <div className="input-group">
                          <input
                            type={passwordVisibility.password ? "text" : "password"}
                            placeholder="Enter Your Password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                          />
                           <span
                            className="icon-show-hide"
                            onClick={() => togglePasswordVisibility("password")}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordVisibility.password ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                              <i className="fa-solid fa-eye"></i>
                            )}
                          </span>
                          {errors.password && <div className="error-message">{errors.password}</div>}

                        </div>
                        </div>
                      </div>

                      {/* Remember Me and Forgot Password */}
                      <div className="row">
                        <div className="col-6 pe-0">
                          <div className="sb-checkbox">
                            <input
                              type="checkbox"
                              className="sb-checkbox__input"
                              id="check1"
                              name="rememberMe"
                              checked={formData.rememberMe}
                              onChange={handleInputChange}
                            />
                            <label className="sb-checkbox__label sb-checkbox__label--green" htmlFor="check1">
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 ps-0 text-end">
                          <span>
                            <Link
                              type="button"
                              className="link-button"
                              onClick={() => setIsForgotPassword(true)} // Only show forgot password section
                            >
                              Forgot Password
                            </Link>
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Show forgot password form if the user clicked on "Forgot Password" */}
                  {isForgotPassword && (
                    <div className="forgot-password-section">
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="forgotPasswordEmail">Enter your email:</label>
                        </div>
                        <div className="col-12 form_input">
                          <input
                            type="email"
                            id="forgotPasswordEmail"
                            value={forgotPasswordEmail}
                            onChange={(e) => setForgotPasswordEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn w-100 theme_btn"
                            onClick={handleForgotPassword}
                          >
                            Send Reset Link
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Submit login form */}
                  {!isForgotPassword && (
                    <div className="row">
                      <div className="col-12">
                        <input
                          className="btn w-100 theme_btn"
                          value="Login"
                          type="submit"
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 text-center">
                      <span className="subscription-info">
                        Don't have an account?
                        <b style={{ paddingLeft: "5px" }}>
                          <Link
                            to={'/register'}
                            className="subscription-link"
                            style={{ cursor: "pointer" }}
                          >
                            Register Now
                          </Link>
                        </b>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block p-0 col-md-6 form_img_container">
            <div className="form-img">
              <Link to={``}>
                <img loading="lazy" src="assets/images/login-poster.png" alt="form_img" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default LoginPage;
