import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import AOS from 'aos';
import axios from 'axios';
import axiosInstance from "../Auth/axiosInstance";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const[metaData, setMetaData] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState({ success: null, message: "" });

  const BASE_URL = process.env.REACT_APP_APP_URL;

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    // Fetch API data
    const fetchContactData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_URL}/api/v1/topic/196`);
        setContactData(response.data.topic[0]);
        setMetaData(response.data);
      } catch (error) {
  
        toast.error("Error fetching contact data:");
      }
      finally {
        setLoading(false);
    }
    };
    
    fetchContactData();
  }, []);
   // Handle input changes
   const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ success: null, message: "" });

    try {
      const response = await axiosInstance.post(`${BASE_URL}/api/get-in-touch`, {
        name: formData.name, // Extract first name 
        email: formData.email,
       
        message: formData.message,
      });

      if (response.status === 200) {
        // setStatus({ success: true, message: "Mail sent successfully!" });
        setFormData({ name: "", email: "", message: "" });
        toast.success("Mail sent successfully!");
      }
    } catch (error) {
      console.error("Error sending mail:", error);
      // setStatus({ success: false, message: "Failed to send mail. Please try again later." });
      toast.error("Failed to send mail. Please try again later.");
      
    }
  };


  if (loading) {
    return (
      <p>
     <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <p className="pera hero skeleton">
                                
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                            <div  className="btn-skeleton hero skeleton"></div>
                            <div className="hero-btn-container d-flex gap-1">
                                <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero  skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
     </section>
  </p>
    );
  }
  // const hours = contactData.fields.find(field => field.title === "Hours")?.value;
  // const [mondayToFriday, weekendHours] = hours ? hours.split("\r\n") : [];

  return (
    <>
    
      <section className="contact-contaier sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6" data-aos="zoom-in-right">
              <div className="content-contact">
                <h2 className="sec_heading">Contact Me</h2>
                <p className="pera">{contactData.fields.find(field => field.title === "Contact- Me")?.value}</p>
              </div>
            </div>
            <div className="col-md-6">
            <form className="contact-form" onSubmit={handleSubmit} data-aos="zoom-in-left">
                <h2 className="sec_heading-stylish">Get in Touch</h2>
                <div className="fields">
                  <input
                    type="text"
                    className="theme_input"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="email"
                    className="theme_input"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <textarea
                    name="message"
                    className="theme_input"
                    placeholder="Type Message here"
                    rows="5"
                    id="Msg"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                  <input
                    type="submit"
                    className="btn theme_btn"
                    value="Submit"
                  />
                </div>
                {status.message && (
                  <p className={status.success ? "text-success" : "text-danger"}>
                    {status.message}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="contact-bg-center">
                <img
                  src="/assets/images/contact-plan.png"
                  className="img"
                  alt="Contact Background"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="we-are-here sec_padding">
        <div className="container">
          <div className="row">
            <div class="col-md-12">
             <h2 className="sec_heading" data-aos="fade-right">{contactData.title}</h2>
            </div>
            <div className="col-sm-6">
              
              <Link to="#" className="box-contact mt-3" data-aos="zoom-in-right">
                <div className="icon">
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Location</h5>
                  <p className="pera">{contactData.fields.find(field => field.title === "Location")?.value}</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-6">
            <Link to="#" className="box-contact mt-3" data-aos="zoom-in-right">
                <div className="icon">
                  <i className="fa-solid fa-clock"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Hours</h5>
                  {mondayToFriday && <p className="pera">{mondayToFriday}</p>}
                  {weekendHours && <p className="pera">{weekendHours}</p>}
                </div>
              </Link>
             
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Contact;
