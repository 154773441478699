import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import slugify from "slugify";
import Blogs from "./Blogs"; // Related blogs component

const BlogComponent = ({ selectedCategory, searchQuery }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topics/7/page/${currentPage}/count/4`;

  useEffect(() => {
    AOS.init({ duration: 1200 });

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(API_URL);
        const blogData = response.data.topics || [];

        // Filter blogs by selected category
        let filteredBlogs = blogData;
        if (selectedCategory) {
          filteredBlogs = blogData.filter((blog) =>
            blog.Joined_categories.some((category) =>
              slugify(category.title, { lower: true }) === selectedCategory
            )
          );
        }

        // Filter blogs by search query
        if (searchQuery) {
          filteredBlogs = filteredBlogs.filter((blog) =>
            blog.title.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }

        setBlogs(filteredBlogs);
        setTotalPages(response.data.totalPages || 1);
      } catch (err) {
        setError("Error fetching blogs");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [API_URL, currentPage, selectedCategory, searchQuery]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const generatePaginationItems = () => {
    let items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Link key={i} to="#" onClick={() => setCurrentPage(i)}>
          <li className={`item ${i === currentPage ? "active" : ""}`}>{i}</li>
        </Link>
      );
    }
    return items;
  };

  return (
    <>
      {blogs.length === 0 ? (
        <section className="articals">
          <div className="container">
            <div className="row g-2 g-sm-4">
              <div className="col-12" data-aos="fade-right">
                {selectedCategory && (
                  <p>We don't have any blogs in this category right now. Please check back later!</p>
                )}
                {!selectedCategory && searchQuery && (
                  <p>No blogs found matching your search. Please try again with different keywords.</p>
                )}
                <h4 className="sec_heading">Related Blogs</h4>
                <hr className="my-" />
                <Blogs /> {/* Related blogs */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        blogs.map((blog, index) => {
          const categorySlug = slugify(blog.Joined_categories[0]?.title || "uncategorized", { lower: true });
          const titleSlug = blog.seo_url_slug;
          const blogUrl = `/blogs/${categorySlug}/${titleSlug}`;

          return (
            <div className="col-md-6 mt-2" key={index} data-aos="zoom-in-up">
              <Link to={blogUrl} className="artical-box">
                <div className="img-artical">
                  <img src={blog.photo_file} alt={blog.title} />
                </div>
                <div className="content-artical">
                  <span className="detail">
                    by {blog.user?.name || "Unknown Author"} | {blog.date} | {blog.Joined_categories[0]?.title || "Uncategorized"}
                  </span>
                  <h3 className="title-artical">{blog.title}</h3>
                  <p className="desc"
                    dangerouslySetInnerHTML={{
                      __html: blog.details
                        ? blog.details.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100) + "..."
                        : "No description available."
                    }}
                  />
                  <button className="btn theme_btn mt-3">Read More</button>
                </div>
              </Link>
            </div>
          );
        })
      )}

      {blogs.length > 0 && totalPages > 1 && (
        <div className="pagination">
          <ul>{generatePaginationItems()}</ul>
        </div>
      )}
    </>
  );
};

export default BlogComponent;
