import React, { createContext, useState, useContext } from "react";

const CategoryContext = createContext();

export const useCategory = () => {
  return useContext(CategoryContext);
};

export const CategoryProvider = ({ children }) => {
  const [categorySlug, setCategorySlug] = useState(null);

  const handleCategorySelect = (categorySlug) => {
    setCategorySlug(categorySlug);
  };

  return (
    <CategoryContext.Provider value={{ categorySlug, handleCategorySelect }}>
      {children}
    </CategoryContext.Provider>
  );
};
