import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom"; // Import Link from react-router-dom
import Footer from "../components/Footer";
import axios from "axios";
import AOS from 'aos';
import slugify from 'slugify';
import { Helmet } from "react-helmet";


const PodcastDetailPage = () => {
  const { seo_url_slug } = useParams(); // Get the seo_url_slug from the URL
  const [ServiceData, setServiceData] = useState(null);
  const [PodcastData, setPodcastData] = useState(null); // State for podcast data
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const BASE_URL = process.env.REACT_APP_APP_URL;
  const SERVICE_API = `${BASE_URL}/api/v1/topic/10`; // Service data API
  const PODCAST_API = `${BASE_URL}/api/v1/topics/25/page/1/count/20`; // Podcast list API

  useEffect(() => {
    AOS.init({
      duration: 1200
    }); // Initialize AOS animations

    // Fetch service data
    axios
      .get(SERVICE_API)
      .then((response) => {
        setServiceData(response.data.topic[0]);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        setError("Error fetching service data");
        setLoading(false);
      });

    // Fetch all podcasts and find the one with matching seo_url_slug
    axios
      .get(PODCAST_API)
      .then((response) => {
        const podcast = response.data.topics.find(podcast =>
          slugify(podcast.seo_url_slug,{ lower: true, strict: true }) === seo_url_slug
        );
        if (podcast) {
          setPodcastData(podcast);
        } else {
          setError("Podcast not found");
        }
      })
      .catch((error) => {
        setError("Error fetching podcast data");
      });
  }, [seo_url_slug]); // Re-run the effect when the seo_url_slug changes

  const extractListItems = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const listItems = doc.querySelectorAll("li");
    return Array.from(listItems).map((item) => item.textContent);
  };

  if (loading) {
    return (
      <>
        <Header />
        <section className="podcast-detail sec_padding skeleton-container">
          <div className="container">
            <div className="row">
              <div className="col-12" data-aos="zoom-in-up">
                <div className="sec_heading text-center skeleton m-auto"></div>
                <p className="pera text-center skeleton m-auto my-2 my-md-3"></p>
              </div>
              <div className="col-12 mb-2 mb-md-4" data-aos="zoom-in-up">
                <div className="detail-podcast-video skeleton"></div>
              </div>
              <div className="col-12 text-center" data-aos="zoom-in-up">
                <div className="btn skeleton"></div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  if (error) return <p>{error}</p>;

  return (
    <>
      <Header />
      <Helmet>
        <title>{PodcastData ? PodcastData.seo_title_en : "Loading..."} - Shape Shifting Mastery</title>
        <meta
          name="description"
          content={PodcastData ? `Read more about ${PodcastData.seo_description_en}.` : "Loading podcast details."}
        />
        <meta name="keywords" content={PodcastData && PodcastData.seo_keywords ? PodcastData.seo_keywords : 'default, keywords'} />
      </Helmet>


      <section className="podcast-detail sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="zoom-in-up">
              <h2 className="sec_heading text-center">
                {PodcastData ? PodcastData.title : "Podcast Title Not Found"}
              </h2>
              <p className="pera text-center">
                {PodcastData ? PodcastData.details : "Podcast details not available."}
              </p>
            </div>
            <div className="col-12" data-aos="zoom-in-up">
              <div className="detail-podcast-video">
                <video
                  controls
                  autoPlay
                  loop
                  muted
                  playsInline
                  controlsList="nodownload"
                  src={PodcastData?.video_url || "https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4"}
                >
                  Your browser does not support the video tag.
                </video>

              </div>
            </div>
            <div className="col-12 text-center" data-aos="zoom-in-up">
              <Link to="/contact" className="btn theme_btn">
                BOOK A DISCOVERY CALL
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Service Data Section */}
      {ServiceData && (
        <section className="sec_padding podcast-detail-content">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-5" data-aos="fade-right">
                <h2 className="sec_heading-stylish text-center">
                  {ServiceData.title}
                </h2>
                <span className="down-arrow">
                  <i className="fa-solid fa-arrow-down-long"></i>
                </span>
              </div>
              <div className="col-lg-5" data-aos="zoom-in-right">
                <div className="content-service">
                  <h1 className="main-heading mb-0">
                    {ServiceData.fields && ServiceData.fields[0]?.value}
                  </h1>
                  <p className="pera">
                    {ServiceData.details?.match(/<p>(.*?)<\/p>/)?.[1]}
                  </p>
                  <ul className="list-with-check">
                    {extractListItems(ServiceData.details).map((item, index) => (
                      <li key={index}>
                        <i className="fa fa-check"></i>
                        <span className="pera">{item}</span>
                      </li>
                    ))}
                  </ul>
                  <Link to="/contact" className="btn theme_btn">
                    BOOK A DISCOVERY CALL
                  </Link>
                </div>
              </div>
              {/* Add the image here */}
              <div className="col-lg-7 mt-3 mt-lg-0 mt-3 mt-sm-4" data-aos="zoom-in-left">
                <div className="img-right top">
                  <img
                    src={ServiceData.photo_file}
                    alt={ServiceData.title}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
};

export default PodcastDetailPage;
