import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import AOS from "aos";
import axios from "axios";
import slugify from "slugify";

const BlogSidebar = ({ onCategorySelect, onSearch }) => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const BASE_URL = process.env.REACT_APP_APP_URL;
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 1200 });

    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/topics/7/page/1/count/10`
        );
        const data = await response.json();
        if (data.topics) {
          setBlogs(data.topics.slice(0, 3));
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogs();
  }, [BASE_URL]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/categories/7`);
        const data = response.data.categories || [];
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    const fetchSocialLinks = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/topic/217`);
        const fields = response.data.topic[0]?.fields;
        if (fields) {
          setSocialLinks(fields);
        }
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchSocialLinks();
  }, [BASE_URL]);

  const filteredLinks = socialLinks.filter(
    (link) => ["Whatsapp", "Instagram", "Facebook"].includes(link.title)
  );

  const handleCategoryClick = (categorySlug) => {
    onCategorySelect(categorySlug);
    setActiveCategory(categorySlug);
  };

  const handleSearchInput = (event) => {
    const query = event.target.value;
    setSearchInput(query);
    onSearch(query); // Pass the search query to the parent component
  };

  const isDetailPage = location.pathname.includes("/blogs/");

  return (
    <div className="blog-sidebar">
      {!isDetailPage && (
        <div className="searchbar" data-aos="fade-up">
          <input
            type="search"
            className="search-input"
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchInput}
          />
          <div className="icon-submit">
            <i className="fas fa-search"></i>
          </div>
        </div>
      )}

      <div className="about-feed feed" data-aos="fade-up">
        <h3 className="heading">About</h3>
        <div className="info">
          <div className="img">
            <img src="/assets/images/Michele.png" alt="Michele" />
          </div>
          <div className="detail">
            <h4 className="name">Michele</h4>
            <p className="desination">Fitness Professional</p>
          </div>
        </div>
        <p className="content">
          Welcome! I’m Michele Drake, an alternative health, wellness, and fitness professional with over 35 years of experience.
        </p>
        <ul className="social">
          <li>
            <i className="fa-solid fa-location-dot"></i>
            <span>Canada</span>
          </li>
          <li>
            {filteredLinks.map((link, index) => {
              const iconClass = `fa-brands fa-${link.title.toLowerCase()}`;
              return (
                <a
                  key={index}
                  href={link.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={iconClass}></i>
                </a>
              );
            })}
          </li>
        </ul>
      </div>

      {!isDetailPage && (
        <div className="category feed" data-aos="fade-up">
          <h3 className="heading">Category</h3>
          <ul className="categoty-list">
            {categories.map((category) => {
              const categorySlug = slugify(category.title, { lower: true });
              return (
                <li className="nav-item" key={category.id}>
                  <NavLink
                    to="#"
                    onClick={() => handleCategoryClick(categorySlug)}
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    {category.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <div className="popular-post feed" data-aos="fade-up">
        <h3 className="heading">Popular Post</h3>
        <div className="PopularPost-list">
          {blogs.map((blog, index) => {
            const categorySlug = slugify(
              blog.Joined_categories[0]?.title || "uncategorized",
              { lower: true }
            );
            const titleSlug = blog.seo_url_slug;
            const blogUrl = `/blogs/${categorySlug}/${titleSlug}`;
            return (
              <Link to={blogUrl} className="item" key={index}>
                <div className="img">
                  <img src={blog.photo_file} alt={blog.title} loading="lazy" />
                </div>
                <p className="content">{blog.title}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
