import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();

  // Split the path into segments
  const pathSegments = location.pathname.split("/").filter((segment) => segment);

  // Function to build the URL path for each breadcrumb item
  const buildPath = (index) => "/" + pathSegments.slice(0, index + 1).join("/");

  // lowecase the first letter of each word in the segment
  const lowecase = (text) => {
    return text
      .split(/[-_]/) // Split by hyphen or underscore
      .map((word) => word.charAt(0).toLowerCase() + word.slice(1)) // lowecase each word
      .join(" ");
  };

  // Custom function to handle page-specific replacements
  const getCustomBreadcrumb = (segment, index) => {
    // if (segment === "blog-detail-page") {
    //   return { name: "Blog", link: "/blog" };
    // }
    // if (segment === "service-detail") {
    //   return { name: "Service", link: "/service" };
    // }
    // if (segment === "podcast-detail-page") {
    //   return { name: "Podcast", link: "/podcast" };
    // }
    // if (segment === "shop-detail-page") {
    //   return { name: "Shop", link: "/shop" };
    // }
    return { name: lowecase(segment), link: buildPath(index) }; // Default behavior
  };

  // If the current URL is the homepage, don't render the breadcrumb
  if (location.pathname === "/") {
    return null; // Do not render breadcrumb on homepage
  }

  return (
    <section className="breadcrumb" data-aos="fade-right">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="items">
              {/* Home Breadcrumb */}
              <li>
                <Link to="/">Home</Link>
              </li>

              {/* Dynamically render other breadcrumbs */}
              {pathSegments.map((segment, index) => {
                const { name, link } = getCustomBreadcrumb(segment, index);
                return (
                  <React.Fragment key={index}>
                    {/* Separator */}
                   
                      <li>
                        <i className="fa fa-angle-right"></i>
                      </li>
                
                    {/* Current Segment */}
                    <li>
                      <Link
                        to={link}
                        className={index === pathSegments.length - 1 ? "active" : ""}
                      >
                        {name}
                      </Link>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
