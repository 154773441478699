import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
const ErrorSection = ({ 
  message = "The data you are looking for does not exist.", // Default error message 
  icon = "fa fa-exclamation-circle", // Default icon 
  className = "alert-danger" // Custom CSS class for styling 
}) => {
  return (
    <>
    <Header />
      <section className="error-section sec_padding">
        <div class="container">
          <div class="row">
            <div class="col-mg-8 col-md-10 col-sm-10 col-12 m-auto">
                <div className={`alert ${className}`} role="alert">
                  <i className={icon}></i> &nbsp;  {message}
                </div>
            </div>
          </div>
        </div>
      </section>
    <Footer/>
    </>
  );
};

export default ErrorSection;
