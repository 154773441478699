import axios from 'axios';

// Get the token from localStorage (if logged in)
// const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
// console.log(csrfToken);
const token = localStorage.getItem('authToken');
const authUrl = process.env.REACT_APP_AUTH_URL;

const axiosInstance = axios.create({
  baseURL: `${authUrl}`, 
  
  // Your Laravel API base URL
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
    'Content-Type': 'application/json',
     Accept: 'application/json',
     
   
     
    
  },
});

export default axiosInstance;
