import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import AOS from 'aos';

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [introText, setIntroText] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [testimonialImage, setTestimonialImage] = useState("");
    const [titlee, setTitle] = useState("");
    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL_TESTIMONIALS = `${BASE_URL}/api/v1/topics/11/page/1/count/50`; // API URL for testimonials
    const API_URL_INTRO_TEXT = `${BASE_URL}/api/v1/topic/177`; // This URL should be the one you use for intro text
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`; // This URL should be the one you use for fetching the testimonial image

    useEffect(() => {
        AOS.init({
            duration: 1200
        }); // Initialize AOS animations

        // Fetch testimonials from the provided API
        const fetchTestimonials = async () => {
            try {
                const response = await axios.get(API_URL_TESTIMONIALS);
                setTestimonials(response.data.topics); // Use the 'topics' array from the response
            } catch (err) {
                setError("Error fetching testimonials");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        const fetchIntroText = async () => {
            try {
                const response = await axios.get(API_URL_INTRO_TEXT);
                const introTitle = response.data.topic[0].fields.find(
                    (field) => field.title === "Component-title"
                );
                setTitle(introTitle?.value || "Title Not Found");
                setIntroText(response.data.topic[0].details);
            } catch (err) {
                console.error("Error fetching intro text", err);
            }
        };

        const fetchTestimonialImage = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                setTestimonialImage(response.data.topic_photo);
            } catch (err) {
                console.error("Error fetching testimonial image", err);
            }
        };

        fetchTestimonials();
        fetchIntroText();
        fetchTestimonialImage();
    }, [API_URL_TESTIMONIALS, API_URL_INTRO_TEXT, API_URL_PHOTO]); // Add dependencies here

   

    

    if (error) return <p>{error}</p>;
  

    return (
        <section className="sec_padding testimonial">
            <div className="container">
                <div className="row overflow-hidden">
                    <div className="col-md-6 col-lg-7">
                        <div className="content-top pr-md-5" data-aos="fade-right">
                        <h6 className="sub_heading">{titlee}</h6>
                            <h2 className="sec_heading-stylish">Best instructors in town!</h2>
                            <p className="title-pera pt-2 pb-3" dangerouslySetInnerHTML={{ __html: introText }} />

                        </div>

                        <div className="testimonial-slide" data-aos="zoom-in-right">
                        <OwlCarousel
                            className="owl-theme"
                            loop={true}
                            margin={10}
                            nav={true}
                            dots={false}
                            autoplay={true}
                            autoplayTimeout={3000}
                            autoplayHoverPause={true}
                            items={1}
                            navText={[
                                '<span class="owl-prev"><i class="fa fa-chevron-left"></i></span>', 
                                '<span class="owl-next"><i class="fa fa-chevron-right"></i></span>'
                            ]}
                        >

                            {testimonials.map((testimonial, index) => (
                                <div className="item-testimonial" key={index}>
                                    <p className="pera-testimonial">{testimonial.details}</p>
                                    <div className="user-box">
                                        <div className="img-user">
                                            <img src={testimonial.photo_file ? testimonial.photo_file : "/assets/images/user/user-default.png"} 
  alt={testimonial.title || "Default User"} />
                                        </div>
                                        <div className="content">
                                            <h5 className="user-name">{testimonial.title}</h5>
                                            {/* <p className="destination">Manager</p> */}
                                            <div className="rating-icon">
                                                {[...Array(5)].map((_, i) => (
                                                    <i className="fa-solid fa-star" key={i}></i>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>

                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="img-testimonial" data-aos="zoom-in-left">
                            <img src={testimonialImage} alt="Testimonials" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
