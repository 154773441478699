import React, { useState, useEffect } from "react";
import axiosInstance from "../Auth/axiosInstance";
import { Link } from "react-router-dom"; // Import Link for navigation

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for handling errors
  const [currentPage, setCurrentPage] = useState(1); // Track the current page for pagination
  const [totalPages, setTotalPages] = useState(1); // Track the total number of pages

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axiosInstance.get("/api/notifications", {
          params: {
            page: currentPage, // Add the current page as a query parameter
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        // Check if the response contains notifications
        if (response.data && response.data.notifications) {
          setNotifications(response.data.notifications); 
          setTotalPages(response.data.pagination.total_pages); // Set the total number of pages
        } else {
          setNotifications([]); 
        }
      } catch (err) {
        console.error("Error fetching notifications:", err);
        setError("Failed to fetch notifications. Please try again.");
      } finally {
        setLoading(false); // Set loading to false after the fetch attempt
      }
    };

    fetchNotifications();
  }, [currentPage]); // Trigger effect when the current page changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update current page
  };

  if (loading) {
    return (
      <>
        <div className="row skeleton-container skeleton-plan">
          <div className="col-12">
            <div className="card mb-2">
              <h5 className="card-header bg-white">
                <div className="heading skeleton"></div>
              </h5>
              <div className="card-body">
                <div className="row">
                  {/* Left Column */}
                  <div className="col-xl-6 order-1 order-xl-0">
                    <div className="mb-1">
                      <div className="mb-1 sub-heading skeleton"></div>
                      <div className="text skeleton"></div>
                    </div>
                    <div className="mb-1 pt-1">
                      <div className="sub-heading skeleton mb-1"></div>
                      <div className="text skeleton"></div>
                    </div>
                    <div className="text skeleton"></div>
                  </div>
                  {/* Buttons */}
                  <div className="col-12 order-2 mt-2 order-xl-0 d-flex gap-2">
                    <div className="btn skeleton"></div>
                    <div className="btn skeleton"></div>
                    <div className="btn skeleton"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="card mb-2">
      <h5 className="card-header pb-1">Notifications</h5>
      <div className="card-body">
        <span>Here are your notifications:</span>
      </div>
      <div className="table-responsive px-3">
        <table className="table table-striped border-top">
          <thead>
            <tr>
              
              <th className="text-nowrap">Messages</th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan="2">{error}</td> {/* Show error message if fetching fails */}
              </tr>
            ) : notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <tr key={index}>
                   {/* SR. No. */}
                  <td>{notification.messages}</td> {/* Display notification message */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No notifications available.</td> {/* Message if no notifications */}
              </tr>
            )}
          </tbody>
        </table>

        {/* Custom Pagination Controls */}
        <div className="pagination">
          {/* Previous Button */}
          <Link
            to="#"
            onClick={() => handlePageChange(currentPage - 1)}
            className={`fa-solid icon pre ${currentPage === 1 ? "disabled" : ""}`}
            aria-disabled={currentPage === 1 ? "true" : "false"} // Adds accessibility
          >
            <i className={`fa-arrow-left ${currentPage === 1 ? "disabled-arrow" : ""}`}></i>
          </Link>

          {/* Page Number Links */}
          <ul className="pagination-items">
            {Array.from({ length: totalPages }, (_, i) => (
              <Link to="#" key={i} onClick={() => handlePageChange(i + 1)}>
                <li className={`item ${currentPage === i + 1 ? "active" : ""}`}>
                  {i + 1}
                </li>
              </Link>
            ))}
          </ul>

          {/* Next Button */}
          <Link
            to="#"
            onClick={() => handlePageChange(currentPage + 1)}
            className={`fa-solid icon next ${currentPage === totalPages ? "disabled" : ""}`}
            aria-disabled={currentPage === totalPages ? "true" : "false"} // Adds accessibility
          >
            <i className={`fa-arrow-right ${currentPage === totalPages ? "disabled-arrow" : ""}`}></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
