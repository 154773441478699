import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import slugify from "slugify";
import AOS from "aos";
import { toast } from "react-toastify"; // Import react-toastify
import { Helmet } from "react-helmet";

const ServiceDetail = () => {
  const { seo_url_slug  } = useParams(); // Get the slug from the route
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topics/2/page/1/count/10`; // You can update this endpoint to get a specific service

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    const fetchServiceDetail = async () => {
      try {
        // Fetch all services from the API
        const response = await axios.get(API_URL);
        const serviceData = response.data.topics.find(
          (item) => slugify(item.title, { lower: true, strict: true }) === seo_url_slug 
        );
        if (serviceData) {
          const { subtitle, cleanDetails } = extractSubtitleAndClean(serviceData.details);
          setService({ ...serviceData, subtitle, details: cleanDetails });
        } else {
          setError("Service not found");
        }
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetail();
  }, [seo_url_slug , API_URL]);

  // Function to extract <h4> and remove it from details
  const extractSubtitleAndClean = (details) => {
    const h4Regex = /<h4[^>]*>(.*?)<\/h4>/;
    const match = details.match(h4Regex);
    const subtitle = match ? match[1] : "No subtitle available";
    const cleanDetails = details.replace(h4Regex, "").trim();
    return { subtitle, cleanDetails };
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // Sending email via the API
      await axios.post(`${BASE_URL}/api/send-discovery-email`, {
        email,
        serviceTitle: service.title,
      });
      
      // Show success toast
      toast.success("Thank you for booking a discovery call! An email has been sent.");
      // Clear email field
      setEmail("");
    } catch (error) {
      toast.error("Error sending the email. Please try again later.");
    }
  };

  if (loading) {
    return (
      <section className="service-detail sec_padding skeleton-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="box-service-details pt-0 pt-md-4 mt-0" data-aos="zoom-in">
                <div className="Presents skeleton m-auto"></div>
                <div className="sec_heading skeleton my-4 m-auto"></div>
                <div className="sub_heading skeleton m-auto"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
    <Helmet>
      <title>{service ? service.seo_title_en : "Loading..."} - Shape Shifting Mastery</title>
      <meta
        name="description"
        content={service ? `Read more about ${service.seo_description_en}.` : "Loading podcast details."}
      />
      <meta name="keywords" content={service.seo_keywords || 'default, keywords'} />
</Helmet>
      <section className="service-detail sec_padding gradient-one">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="box-service-details pt-0 pt-md-4 mt-0" data-aos="zoom-in">
                <h5 className="Presents">
                  <span className="stylish">Michele </span>Presents
                </h5>
                <h3 className="sec_heading text-center">{service.title}</h3>
                <h4 className="sec_heading-stylis text-center">{service.subtitle}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6" data-aos="zoom-in-right">
              <div className="service-detail-img">
                <img src={service.photo_file} alt={service.title} />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6" data-aos="zoom-in-left">
              <h2 className="sec_heading pt-2 pt-md-0">{service.title}</h2>
              <p className="pera" dangerouslySetInnerHTML={{ __html: service.details || "" }} />
              <Link to="/contact" className="btn theme_btn mt-3">
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="subscribe sec_padding">
        <div className="container">
          <div className="row" data-aos="zoom-in">
            <div className="col-12 mb-md-4 mb-3">
              <h6 className="sub_heading text-center">Subscribe</h6>
              <h2 className="sec_heading-stylish text-center">Take the First Step</h2>
              <p className="pera text-center">
                Ready to embark on a transformative journey towards a healthier,
                happier you? Book a call today to discover the metamorphosis awaiting
                you with Michele’s expertise.
              </p>
              <form className="subscribe-box" onSubmit={handleFormSubmit}>
                <input
                  type="email"
                  className="input theme_input"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                <input
                  type="submit"
                  className="btn theme_btn"
                  value="BOOK A DISCOVERY CALL"
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetail;
