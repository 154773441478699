import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Services from "../components/Services";
import Footer from "../components/Footer";
import axios from "axios";
import AOS from 'aos';
import axiosInstance from "../Auth/axiosInstance";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const ServicePage = () => {
  const [topicData, setTopicData] = useState(null);
  const [sessionData, setsessionData] = useState(null);
  const [ServiceData, setServiceData] = useState(null);
  const [ServiceTwoData, setServiceTwoData] = useState(null);
  const [subscribeData, setsubscribeData] = useState(null);
  const [email, setEmail] = useState("");
  const [message] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  


  const BASE_URL = process.env.REACT_APP_APP_URL;
   // Define BASE_URL
  const API_URL = `${BASE_URL}/api/v1/topic/187`; // Define API_URL
  const SESSION_API_URL = `${BASE_URL}/api/v1/topic/188`; // Define SESSION_URL
  const SERVICE_API = `${BASE_URL}/api/v1/topic/10`;
  const SERVICE_TWO_API = `${BASE_URL}/api/v1/topic/9`;
  const SUBSCRIBE_API = `${BASE_URL}/api/v1/topic/191`;

  useEffect(() => {
    AOS.init(
      {
          duration: 1200
        }
  ); // Initialize AOS animations
    // Fetch topic data from API using the dynamic API_URL
    const fetchApiData = async () => {
      try {
        axios.get(API_URL).then((response) => {
          const topic = response.data.topic[0];
          setTopicData(topic);
        });
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchApiData();
  }, [API_URL]);
  

  useEffect(() => {
    const fetchsessionData = async () => {
      try {
        const response = await axios.get(SESSION_API_URL);
        setsessionData(response.data.topic[0]);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchsessionData();
  }, [SESSION_API_URL]);

  useEffect(() => {
    // Fetch data from Serviceone_API
    axios
      .get(SERVICE_API)
      .then((response) => {
        setServiceData(response.data.topic[0]);
      })
      .catch((error) => console.error("Error fetching service data:", error));
  }, [SERVICE_API]);

  useEffect(() => {
    const fetchservicetwoData = async () => {
      try {
        const response = await axios.get(SERVICE_TWO_API);
        setServiceTwoData(response.data.topic[0]);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchservicetwoData();
  }, [SERVICE_TWO_API]);

  useEffect(() => {
    const fetchsubscribeData = async () => {
      try {
        const response = await axios.get(SUBSCRIBE_API);
        setsubscribeData(response.data.topic[0]);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchsubscribeData();
  }, [SUBSCRIBE_API]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
};
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
      const response = await axiosInstance.post(
          `/api/send-discovery-email`,
          { email });
      toast.success(response.data.message ); 
  } catch (error) {
    toast.error("Failed to send email");
  }
};
const title = topicData?.fields?.find(field => field.title === "Component-title")?.value || 'Default Title';


  const extractListItems = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const listItems = doc.querySelectorAll("li");
    return Array.from(listItems).map((item) => item.textContent);
  };
  if (loading) {
    return (
      
     <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <p className="pera hero skeleton">
                                
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                            <div  className="btn-skeleton hero skeleton"></div>
                            <div className="hero-btn-container d-flex gap-1">
                                <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero  skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
     </section>
 
    );
  }  if (error) return <p>{error}</p>;

  return (
    <>
      <Header />
      <Helmet>
  <title>Our Services - Shape Shifting Mastery </title> 
  <meta name="description" content={`Our Services`} />
</Helmet>
      {/* ----------banner-section--inner-page-start---  */}
      {topicData &&
       (
        <section className="inner-banner-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-right">
                <div className="banner-caption">
                  <div>
                    <h5 className="text-banner-badge">Services</h5>
                    <h1 className="main-heading mb-0">{title}</h1>
                  </div>
                  <p
                    className="pera"
                    dangerouslySetInnerHTML={{ __html: topicData.details }}
                  ></p>
                  <div className="d-flex gap-3 justify-content-between">
                    <Link to="https://masterhabits.com/" target="_blank" className="btn theme_btn">
                      Take a Quiz Now
                    </Link>
                    <Link to="/podcasts" className="podcast-btn">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                      <span>
                        <i className="fa fa-play"></i>
                      </span>
                      <p className="podcast-text-none">Free Podcast</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-left">
                <div className="banner-img">
                  <video controls="true"
                   autoplay="" loop="" muted="muted" playsInline="" 
                    src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* ----banner-section-inner-page-end-----  */}
      <Services />

      {/* ---session-start--- */}
      {sessionData && (
        <section className="session sec_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-right">
                <h1 className="main-heading mb-0">
                  {sessionData.title ||
                    "Health Coaching & Holistic Makeover Coaching 1:1 Session"}
                </h1>
               <p
  className="pera"
  dangerouslySetInnerHTML={{
    __html: sessionData.details?.match(/<p>(.*?)<\/p>/)?.[1] || "Default description text...",
  }}
/>

                <ul className="list-with-check">
                  {extractListItems(sessionData.details).map((item, index) => (
                    <li key={index}>
                      <i className="fa fa-check"></i>
                      <span className="pera">{item}</span>
                    </li>
                  ))}
                </ul>
                <Link to="/services" className="btn theme_btn">
                  Learn More
                </Link>
              </div>
              <div className="col-lg-6  mt-lg-0 mt-3 mt-sm-4" data-aos="zoom-in-left">
                <div className="right-img">
                  {/* <img src="assets/images/service-three.png" alt="" /> */}
                  <img
                    src={
                      sessionData?.photo_file ||
                      "assets/images/service-three.png"
                    }
                    alt="Michele"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ---session-end--- */}

      {/* ----single-services-start  --  */}
      {ServiceData && ServiceTwoData && (
        <section className="sec_padding single-sevice-container">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-5" data-aos="fade-right">
              
                <h2 className="sec_heading-stylish text-center">Services</h2>
            
                <span className="down-arrow">
                  <i className="fa-solid fa-arrow-down-long"></i>
                </span>
              </div>
              <div className="col-lg-5" data-aos="zoom-in-right">
                <div className="content-service">
                  <h1 className="main-heading mb-0">
                    {ServiceData.fields && ServiceData.fields[0]?.value}
                  </h1>
                  <p className="pera">
                    {ServiceData.details?.match(/<p>(.*?)<\/p>/)?.[1]}
                  </p>
                  <ul className="list-with-check">
                    {extractListItems(ServiceData.details).map(
                      (item, index) => (
                        <li key={index}>
                          <i className="fa fa-check"></i>
                          <span className="pera">{item}</span>
                        </li>
                      )
                    )}
                  </ul>
                  <Link to="/services" className="btn theme_btn">
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 mt-3 mt-lg-0 mt-3 mt-sm-4" data-aos="zoom-in-left">
                <div className="img-right top">
                  <img src={ServiceData.photo_file} alt={ServiceData.title} />
                </div>
              </div>
              <div className="col-lg-7 mt-3 mt-lg-0 mt-3 mt-sm-4 order-4 order-lg-3" data-aos="zoom-in-right">
                <div className="img-right">
                  <img
                    src={ServiceTwoData.photo_file}
                    alt={ServiceTwoData.title}
                  />
                </div>
              </div>
              <div className="col-lg-5  mt-3 mt-lg-0 mt-3 mt-sm-4 order-3" data-aos="zoom-in-left">
                <div className="content-service two">
                  <h1 className="main-heading mb-0">{ServiceTwoData.title}</h1>
                  <p className="pera">
                    {ServiceTwoData.details?.match(/<p>(.*?)<\/p>/)?.[1]}
                  </p>
                  <ul className="list-with-check">
                    {extractListItems(ServiceTwoData.details).map(
                      (item, index) => (
                        <li key={index}>
                          <i className="fa fa-check"></i>
                          <span className="pera">{item}</span>
                        </li>
                      )
                    )}
                  </ul>
                  <Link to="/service" className="btn theme_btn">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ----single-services-end --  */}

      {/* ---Subscribe-start---  */}
      {subscribeData && (
        <section className="subscribe sec_padding">
          <div className="container">
            <div className="row" data-aos="zoom-in">
              <div className="col-12 mb-md-4 mb-3">
                <h6 className="sub_heading text-center">Subscribe</h6>
                <h2 className="sec_heading-stylish text-center">
                  {subscribeData.title}
                </h2>
                <p
                  className="pera text-center"
                  dangerouslySetInnerHTML={{
                    __html: subscribeData.details || "Default text...",
                  }}
                ></p>
                <form className="subscribe-box" onSubmit={handleSubmit}>
                            <input
                                type="email"
                                className="input theme_input"
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                            />
                            <button type="submit" className="btn theme_btn">
                                BOOK A DISCOVERY CALL
                            </button>
                        </form>
                        {message && <p className="text-center">{message}</p>}
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ---Subscribe-end---  */}
      <Footer />
    </>
  );
};

export default ServicePage;
