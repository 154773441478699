import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import slugify from "slugify";
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL;

  const API_URL = `${BASE_URL}/api/v1/topics/7/page/1/count/10`;

  useEffect(() => {
    AOS.init({ duration: 1200 });

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(API_URL);
        // Limit to first 3 blogs
        setBlogs(response.data.topics.slice(0, 3));
      } catch (err) {
        setError("Error fetching blogs");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [API_URL]);

  

  if (error) return <p>{error}</p>;

  return (
    <section className="sec_padding articals">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-md-4 mb-1" data-aos="fade-right">
            {/* <h6 className="sub_heading text-center">Blogs</h6>
            <h2 className="sec_heading-stylish text-center">Together We Will</h2> */}
          </div>
          {/* Limit blogs to 3 */}
          {blogs.map((blog, index) => {
            const categorySlug = slugify(blog.Joined_categories[0]?.title || "uncategorized", { lower: true });
        const titleSlug = blog.seo_url_slug;  // Using seo_url_slug here for the blog URL
        const blogUrl = `/blogs/${categorySlug}/${titleSlug}`;
            return (
            <div className="col-md-4 mt-2" data-aos="fade-up" key={blog.id}>
            
              <Link to={blogUrl} className="artical-box">
                <div className="img-artical">
                  <img
                    src={blog.photo_file }
                    alt={blog.title}
                  />
                </div>
                <div className="content-artical">
                  <span className="detail">
                    by {blog.user.name} | {blog.date} |{" "}
                    {blog.Joined_categories[0]?.title || "Uncategorized"}
                  </span>
                  <h3 className="title-artical">{blog.title}</h3>
                  <p className="desc">
                    {blog.details
                      ? blog.details.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100) + "..."
                      : "No description available."}
                  </p>
                  <Link
                    to={blogUrl}
                    className="btn theme_btn mt-3"
                  >
                    Read More
                  </Link>
                </div>
              </Link>
            </div>
          );
          })}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
